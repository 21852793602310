import React from 'react';
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import { ToastStore } from 'react-toasts';
import StellarSdk from 'stellar-sdk';
import CryptoJS from 'crypto-js';
import { GLOBE } from './globeVars/index';
import iconIdent from '../containers/Pages/Account/components/iconIdent';
import ResultCodes from "../endpoints/ResultCodes";
import { getFederationId } from "../endpoints/StellarAPI";
import getPrice from "../containers/App/GetPrice";
import freighterApi from "@stellar/freighter-api";
import albedo from '@albedo-link/intent'
// import scopuly from '@scopuly/intent'
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {Card, CardBody, Col, Row} from "reactstrap";
import {checkHashPool, getPriceGeckoXlmUsd} from "../endpoints/API";



export const getBasePrice = async () => {

  const interval = 1;
  const last_time = localStorage.getItem('last_time_price');

  if (!last_time) { get_price() }
  else {
    let dateB = moment(),
        dateC = moment(last_time),
        differ = dateB.diff(dateC, 'minutes');

    if (differ >= interval) { get_price(); }
    else {
      const loc_price = localStorage.getItem('price_xlm_usd')
      if (loc_price) { return JSON.parse(loc_price) }
      else { get_price() }
    }
  }

  function get_price() {
    getPriceGeckoXlmUsd().then(result => {
      localStorage.setItem('price_xlm_usd', JSON.stringify(result));
      localStorage.setItem('last_time_price', moment().format());
    })
  }

  return getPriceGeckoXlmUsd()
}

export function shortAddr(address, num = 4) {
	if (address) {
		var first = address.substring(0,num)
		var last = address.substr(-num);
		var short = first+'...'+last;
		return short;
	}
	else {
		return 0;
	}
}


export const shortTransaction = (hash) => {
  return <Link to={`/transaction/${hash}`}><b>{shortAddr(hash, 4)}</b></Link>
}

export function shortAddress(address, target, num) {
	const name = getNameAddr(address);

	if (!address) return

	return (
		<a
      target={target ? target : null}
      href={`/account/${address}`}>{iconIdent(address, 'icon-ident-id')} <b>{shortAddr(address, num ? num : 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
	)
}

export function shortPool(pool_id, target) {

  if (!pool_id) return

  return (
    <a
      target={target ? target : null}
      className={'uppercase'}
      href={`/pool/${pool_id}`}>
      &nbsp;{iconIdent(pool_id, 'icon-indent')} {' '}
      <b>
        {/*{shortAddr(pool_id, 4)}*/}
        liquidity pool
      </b>
    </a>
  )
}

export function itemType(type) {
	return type.replace(/_/g," ")[0].toUpperCase() + type.replace(/_/g," ").slice(1);
}

export function numFormat(value, separ) {
	if (value) {
		var res, slice;
		if (typeof value === 'string') {
			value = Number(value).toFixed(separ)
			slice = value.slice(-1);
		}
		if (typeof value === 'number') {
			value = value.toFixed(separ)
			slice = value.slice(-1);
		}

		if (slice === '0') {
			value = parseFloat(value).toString();
		}

		var indexOf = value.indexOf('.') > -1;

		if (indexOf) {
			var a = value.split('.')[0];
			var b = value.split('.')[1];
			var replace = a.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
			res = (replace+'.'+b);
		}
		else {
			res = value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
		}
		return res;
	}
	else {
		return 0;
	}
}

export function formatDate(time, type) {
	return moment(time).format(type === 'short' ? "DD/MM/YY HH:mm:ss" : "DD MMMM YYYY HH:mm:ss");
}


// export function get_my_wallets() {

//   let wallets_from_storage = localStorage.getItem('wallets');

//   let wallets_parse;

//   if (wallets_from_storage) {
//       wallets_from_storage = window.atob(wallets_from_storage);
//       wallets_parse = JSON.parse(wallets_from_storage);
//       //...

//       wallets_parse.forEach((item, index) => {

//         console.log('item: ', item)

//         if (Object.keys(item).length > 3) {

//           console.log('item3: ', item)

//           var bytes  = CryptoJS.AES.decrypt(window.atob(item.sk.toString()), item.pk);
//           item.sk = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
//         }
//       })
//   } else {
//       wallets_parse = false;
//   }

//   return wallets_parse;
// }



export function get_loc_wallets() {

  let wallets_from_storage = localStorage.getItem('wallets');

  let wallets_parse = false;

  if (wallets_from_storage) {
      wallets_from_storage = window.atob(wallets_from_storage);
      wallets_parse = JSON.parse(wallets_from_storage);
      // console.log('wallets_parse: ', wallets_parse)

      wallets_parse.forEach((item, index) => {
        // console.log('get_loc_wallets item: ', item)

        if (typeof(item) === 'string') {
          delete wallets_parse[index];
          localStorage.setItem('wallets', window.btoa(JSON.stringify(wallets_parse)))
          get_loc_wallets()
          return
        }

        if (Object.keys(item).length > 3) {
          if (item.type === 'encrypt') {
            // console.log('get_loc_wallets item > 3: ', item)

            if (item.sk !== '') {

              var plaintext;
              var bytes  = CryptoJS.AES.decrypt(window.atob(item.sk.toString()), item.pk);
              // console.log('bytes: ', bytes);

              try {
                  plaintext = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
                  // console.log('plaintext: ', plaintext);

                  if (plaintext) {
                    item.sk = plaintext;
                    // console.log('item.sk: ', item.sk);
                  }
                  else {
                    // console.log('plaintext bad');
                  }
              }
              catch (err) {
                  // console.log('err: ', err);
                  plaintext = "";
                  // ToastStore.error('Encryption error...');
              }

              // if (bytes) {
              //   if (window.btoa(bytes.toString(CryptoJS.enc.Utf8))) {
              //     item.sk = window.btoa(bytes.toString(CryptoJS.enc.Utf8));
              //     console.log('item.sk: ', item.sk);
              //   }
              // }
            }
            else {
              swal({
                title: 'Secret key not found!',
                text: `The secret key was not found on the ${shortAddr(item.pk, 4)} account. Try to de-authorize this account and then authorize it again.`,
                icon: 'warning'
              })
            }
          }
        }
      })
  }
  // else {
  //     wallets_parse = false;
  // }

  return wallets_parse;
}


export function baseReserve() {
	return 0.5;
}

export function minBalance() {
	return (baseReserve() * 2);
}

export function nativeBalance(account) {
	let native_balance = account.balances.filter(item => {
	  return item.asset_type === 'native';
	});

	return Number(native_balance[0].balance);
}


export function lessOneXLMAalert(account) {
	swal({
	  title: "Minimum Account Balance!",
	  text: `For transactions on the balance must be more than ${minBalance() + (account.subentry_count * baseReserve())} XLM`,
	  icon: "warning",
	});
}


const verifiCode = async (code) => {

  const gkey = localStorage.getItem('gkey');
  const email = localStorage.getItem('user');

  const bytes  = CryptoJS.AES.decrypt(gkey.toString(), email);
  const secret = bytes.toString(CryptoJS.enc.Utf8);

  const res = await axios(`${GLOBE.API_URL}/verify_auth_code/?code=${code}&secret=${secret}`);

  // if (res.data === 'FAILED') {
  //   ToastStore.error(`Incorrect value`);
  // }
  // else if (response.data === 'OK') {

  //   return 'accept';
  // }
}

export const getDirectory = async (addess) => {
  const res = await axios(`https://api.stellar.expert/api/explorer/public/directory/${addess}`);
  // console.log('res: ', res)
  if (res.data) {
    return res.data;
  }
}


export const getClaimantStatus = (item, claimant) => {

  // console.log('item: ', item)
  // console.log('predicate: ', claimant.predicate)

  let status = 'Pending', end_time = ''

  if (claimant.predicate.and) {

    status = new Date() > new Date(claimant.predicate.and[0].not ? claimant.predicate.and[0].not.abs_before : claimant.predicate.and[0].abs_before) ?
      'Pending'
      : new Date() > new Date(claimant.predicate.and[1].not ? claimant.predicate.and[1].not.abs_before : claimant.predicate.and[1].abs_before) ?
        'Expired' : 'Upcoming'
  }

  if (claimant.predicate.abs_before) {

    status = new Date() < new Date(claimant.predicate.abs_before) ?
      'Pending'
      : new Date() > new Date(claimant.predicate.abs_before) ? 'Expired' : 'Upcoming'
  }

  if (claimant.predicate.rel_before) {
    let rel_before = Number(claimant.predicate.rel_before)
    const created_at = new Date(item.created_at);
    created_at.setSeconds(created_at.getSeconds() + rel_before);
    end_time = created_at

    if (new Date(item.created_at) > new Date(end_time)) {
      status = 'Expired'
    }
    if (new Date(item.created_at) < new Date(end_time)) {
      status = 'Pending'
    }
  }

  if (claimant.predicate.not) {
    status = new Date() < new Date(claimant.predicate.not.abs_before) ? 'Pending'
      : new Date() > new Date(claimant.predicate.not.abs_before) ? 'Expired' : 'Upcoming'

    if (claimant.predicate.not.abs_before_epoch) {
      status = new Date() > new Date(claimant.predicate.not.abs_before) ? 'Pending' : 'Upcoming'
      end_time = 0
    }
  }

  return {
    status, end_time
  }
}



export const getAuth = async () => {

	let auth_method = localStorage.getItem('2fa');

  if (!auth_method) {
    return 'need_deauth_all'
  }

	else if (auth_method === 'false') {
		return 'accept';
	}

	else if (auth_method === 'gooauth') {
		var enter_gcode = await swal({
      title: "Google Autenicator Code",
      text: `Enter the code from the Google Autenicator app.`,
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter your Google-code (6 digits)",
          type: "number",
        },
      },
      buttons: {
        cancel: true,
        confirm: true,
      },
    })

    if (enter_gcode) {

      const code = enter_gcode;
      const gkey = localStorage.getItem('gkey');
      const email = localStorage.getItem('user');

      const bytes  = CryptoJS.AES.decrypt(gkey.toString(), email);
      const secret = bytes.toString(CryptoJS.enc.Utf8);

      const res = await axios(`${GLOBE.API_URL}/verify_auth_code/?code=${code}&secret=${secret}`);

      if (res.data === 'FAILED') {
        ToastStore.error(`Incorrect value`);
      }
      else if (res.data === 'OK') {

        return 'accept';
      }
    }
	}

	else if (auth_method === 'pincode') {
	  var enter_pin = await swal({
	    title: "PIN-code",
	    text: `Enter your PIN code to authorize this transaction.`,
	    icon: "info",
	    content: {
	      element: "input",
	      attributes: {
	        placeholder: "Enter your PIN-code (4 digits)",
	        type: "password",
	      },
	    },
	    buttons: {
	      cancel: true,
	      confirm: true,
	    },
	  })

	  if (enter_pin) {

	  	let format = 4;
	  	if (isNaN(enter_pin)) {
	  	  ToastStore.error('The value entered must be a number');
	  	}
	  	else if (enter_pin.length < format || enter_pin.length > format) {
	  	  ToastStore.warning(`PIN code must be ${format} digits.`);
	  	}
	  	else {

	  		let pin_text = localStorage.getItem('pin');

	  		let bytes  = CryptoJS.AES.decrypt(pin_text.toString(), auth_method);
	  		let pin = bytes.toString(CryptoJS.enc.Utf8);

	  	  if (pin === enter_pin) {
	  	    ToastStore.info('PIN code authorization!');

	  	    return 'accept';
	  	  }
	  	  else {
	  	    ToastStore.error('The entered PIN code does not match the one set.');
	  	  }
	  	}
	  }
	}
}


export const needDeauthAll = () => {
  swal({
    title: "Suspicious activity!",
    text: `Attention! Detected suspicious activity in your browser. For security reasons, deauthorize all addresses. Then, if necessary, authorize them. Deauthorize all addresses right now?`,
    icon: "warning",
    buttons: {
      cancel: true,
      confirm: true,
    },
  })
  .then((confirm) => {
    if (confirm) {

      swal({
        title: "Confirmation",
        text: `Deauthorize all Addresses right now?`,
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm) {
          localStorage.removeItem('wallets');
          ToastStore.success("All Accounts have been successfully deauthorized. Now you can authorize them again.");
        }
      })
    }
  })
}

export const setPairToLocal = (pair) => {

  let local_pairs = localStorage.getItem('pairs');

  if (!local_pairs) {
    let pairs = [];
    pairs.push(pair);
    pairs = JSON.stringify(pairs);
    localStorage.setItem('pairs', pairs);
  }
  else {
    local_pairs = JSON.parse(local_pairs);

    local_pairs.forEach((item, index) => {
      if (item.sell_asset.asset_code === pair.sell_asset.asset_code && item.sell_asset.asset_issuer === pair.sell_asset.asset_issuer) {
        if (item.buy_asset.asset_code === pair.buy_asset.asset_code && item.buy_asset.asset_issuer === pair.buy_asset.asset_issuer) {
          local_pairs.splice(index, 1);
        }
      }
    })

    local_pairs.push(pair);
    local_pairs = JSON.stringify(local_pairs);
    localStorage.setItem('pairs', local_pairs);

    // if (!is_item) {
    //
    //   local_pairs.unshift(pair);
    //   local_pairs = JSON.stringify(local_pairs);
    //
    //   localStorage.setItem('pairs', local_pairs);
    // }
  }
}


export const getNameAddr = (address) => {

  let local_favorites = localStorage.getItem('favorites');

	let name = false;

  if (local_favorites) {
    local_favorites = JSON.parse(local_favorites);

    local_favorites.addresses.forEach((item, index) => {
      if (item.address === address) {
        name = item.name;
      }
    });
  }

	// my wallets
	if (!name) {
		const wallets = get_loc_wallets();

		if (wallets) {
			wallets.forEach((item, index) => {
				if (item.pk === address) {
					name = item.title;
				}
			});
		}
	}

	return name;
}



export const checkAuth = async () => {

  const wallets = get_loc_wallets();

  if (!wallets) {
    var alert = await swal({
      title: 'Address not authorized',
      text: 'To continue, you need to connect wallet. Do you want to authorize the Wallet right now?',
      icon: 'info',
      buttons: {
        cancel: true,
        confirm: true,
      },
    })

    if (alert) {
      return true;
    }
  }
}


export const getTitle = (title, description) => {

  if (title) {
    window.document.title = title + ' | Scopuly';
    window.document.querySelector('meta[property="og:title"]').setAttribute("content", title);
  }

  if (description) {
    window.document.querySelector('meta[name="description"]').setAttribute("content", description);
    window.document.querySelector('meta[property="og:description"]').setAttribute("content", description);
  }

}


export const signTransaction = (signers, transaction) => {
  // console.log('signTransaction: ', transaction)

  const wallets = get_loc_wallets();
  wallets.map((wallet_item) => {
    signers.map((item, index) => {

      if (item === wallet_item.pk ) {
        transaction.sign(StellarSdk.Keypair.fromSecret(atob(wallet_item.sk)));
      }
    })
  });

  return transaction;
}


export const showErrorMessage = (error, type) => {
  console.error('error: ', error);
  console.error('error.response: ', error.response);
  console.log('type: ', type)

  let message, data, tx_code, title;

  if  (!error.response) {
    title = 'Error';
    const invalidEncoded = 'Error: invalid encoded string'
    const errorToString = error.toString()
    message = `<div>${errorToString} ${errorToString === invalidEncoded ? '<span><br/><b>Try to deauthorize the wallet and sign in again.</b></span>' : '<span></span>'}</div>`;
  }
  else if (error.response.data) {
    if (error.response.data.extras) {
      data = error.response.data.extras;
      tx_code = data.result_codes.operations ? data.result_codes.operations[0] : data.result_codes.transaction;

      console.log('tx_code: ', tx_code)
      console.log('ResultCodes: ', ResultCodes)
      console.log('ResultCodes[tx_code]: ', ResultCodes[tx_code])

      let resultCodes;
      if (!ResultCodes[tx_code]) {
        if (type === 'payment' || type === 'path_payment') {
          resultCodes = ResultCodes.path_payment;
        }
        if (type === 'manage_offer') {
          resultCodes = ResultCodes.manage_offer;
        }
        if (type === 'change_trust') {
          resultCodes = ResultCodes.change_trust;
        }
        if (type === 'claim') {
          resultCodes = ResultCodes.claim;
        }
        if (type === 'liquidity') {
          resultCodes = ResultCodes.liquidity;
        }
      } else {
        resultCodes = ResultCodes;
      }

      console.log('resultCodes: ', resultCodes)

      message = `<div>${resultCodes[tx_code] ? resultCodes[tx_code] : 'Incorrect data entered. Check the entered values.'}</div>
               <div><p>Error code: <b>${tx_code}</b></p></div>`;
    }
    else {
      message = `<div><p>Error code: <b>${error.response.data.title}</b></p></div>`;
    }

    title = error.response.data.title
  }
  else if (error.response) {
    title = error.response.title;
    message = `<div><p>${error.response.detail}</p></div>`;
  }


  const element = document.createElement("span");
  element.innerHTML = message;

  swal({
    title: title,
    content: element,
    icon: 'error'
  });
}


export const searchProcess = async (value) => {

  const length = value.length;
  const domain = value.split('*')[1];
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  const validDomain = reg.test(domain)

  if (length === 56) {
    window.location.href = window.location.origin+'/account/'+value;
  }
  if (length === 72) {
    window.location.href = window.location.origin+'/claimable-balance/'+value;
  }
  else if (validDomain) {
    getFederation(value)
  }
  else if (length <= 12 && isNaN(value)) {
    window.location.href = window.location.origin+'/assets/'+value;
  }
  else if (length <= 12 && !isNaN(value)) {
    window.location.href = window.location.origin+'/offer/'+value;
  }
  else if (length === 64) {
    checkHashPool(value).then(result => {
      if (result.data.pools.length) {
        window.location.href = window.location.origin+'/pool/'+value;
      } else {
        window.location.href = window.location.origin+'/transaction/'+value;
      }
    })
  }
  else if (!isNaN(value) && length > 17) {
    window.location.href = window.location.origin+'/operation/'+value;
  }

  function getFederation(name) {

    getFederationId(name)
      .then(result => {
        window.location.href = window.location.origin+'/account/'+result.account_id;
      })
      .catch(error => {
        console.log('error: ', error)
        ToastStore.error('Federation name not found...');
      });
  }
}


export const getAsset = (item, image, color, width= 20) => {
  // console.log('getAsset item: ', item)

  let asset = {}

  if (typeof item !== 'object' && item.indexOf(':') + 1) {
    const assetSplit = item.split(':')

    if (assetSplit[0] === 'XLM' && assetSplit[1] === 'native') {
      asset.asset_type = 'native'
    }
    asset.asset_code = assetSplit[0]
    asset.asset_issuer = assetSplit[1]
  }
  else if (item === 'native' || item.asset_issuer === 'native') {
    asset.asset_type = 'native'
    asset.asset_code = 'XLM'
    asset.asset_issuer = 'native'
  }
  // else if (item.source_asset_type === 'native') {
  //   asset.asset_type = 'native'
  //   asset.asset_code = 'XLM'
  //   asset.asset_issuer = 'native'
  // }
  // else if (item.source_asset_type && item.source_asset_type !== 'native') {
  //   asset.asset_code = item.source_asset_code
  //   asset.asset_issuer = item.source_asset_issuer
  // }
  else {

    asset = item
  }

  // console.log('asset: ', asset)

  const render_image = () => {
    if (image) {
      return (
        // <span className={'wr-balance-poll-item'}>
        //   <span className={'balance-pool-item'}>
        //     <span className={'pool-share'}><img src={image} alt="" style={{width: asset.asset_issuer === 'native' ? 25 : 23}}/></span>
        //   </span>
        // </span>
        <img src={image} className={'asset-icon-sm'} alt={asset.asset_code} style={{width: width}} />
      // asset-icon-balance , asset-icon-sm
      )
    }
  }

  return <span>{render_image()} <a href={asset.asset_type === 'native' ? '/native' : `/trade/${asset.asset_code}-XLM/${asset.asset_issuer}/native`} className={`text-${color ? color : 'warning' }`}><b>{asset.asset_type === 'native' ? 'XLM' : asset.asset_code}</b></a></span>
}


export const getSigner = (account) => {
  const wallets = get_loc_wallets()

  if (wallets) {
    return wallets.filter((item) => {
      return item.pk === account;
    })[0];
  }
  return false
}


export const checkConnectProvider = (sourceAddress) => {
  const wallets = get_loc_wallets()
  const connectProvider = wallets.some(item => item.provider && item.pk === sourceAddress)
  return connectProvider
}


export const signConnectProvider = (transaction, sourceAddress) => {

  const wallets = get_loc_wallets()
  const passPhrase = "Public Global Stellar Network ; September 2015"
  const connectProvider = wallets.filter(item => item.provider && item.pk === sourceAddress)

  // Freighter
  if (connectProvider[0].provider === "freighter") {
    const xdr = transaction.toEnvelope().toXDR('base64');

    async function signProvider() {
      return freighterApi.signTransaction(xdr)
    }

    return signProvider().then((result) => {
      return new StellarSdk.Transaction(result, passPhrase);
    })
    .catch(error => {
      errorConnectProvider(error)
    });
  }

  //  Albedo
  if (connectProvider[0].provider === "albedo") {
    const xdr = transaction.toEnvelope().toXDR('base64');

    return albedo.tx({ xdr })
      .then(res => {
        console.log(res.xdr, res.tx_hash, res.signed_envelope_xdr, res.network, res.result)

        return new StellarSdk.Transaction(res.signed_envelope_xdr, passPhrase);
      })
      .catch(error => {
        errorConnectProvider(error.message)
      })
  }

  //  Scopuly
  // if (connectProvider[0].provider === "scopuly") {
  //   const xdr = transaction.toEnvelope().toXDR('base64');
  //
  //   return scopuly.tx({ xdr })
  //     .then(res => {
  //       console.log(res.xdr, res.tx_hash, res.signed_envelope_xdr, res.network, res.result)
  //
  //       return new StellarSdk.Transaction(res.signed_envelope_xdr, passPhrase);
  //     })
  //     .catch(error => {
  //       errorConnectProvider(error.message)
  //     })
  // }

  // Rabet
  if (connectProvider[0].provider === "rabet") {
    const xdr = transaction.toEnvelope().toXDR('base64');
    const network = 'mainnet'; // testnet

    return  window.rabet.sign(xdr, network)
      .then(result => {
        return new StellarSdk.Transaction(result.xdr, passPhrase);
      })
      .catch(error => {
        console.error(`Error: ${error.message}`)
        errorConnectProvider(error.message)
      });
  }

  // Scopuly

  function errorConnectProvider(message) {
    swal({
      title: "Error",
      text: message,
      icon: "error",
    })
  }
}


export const getIconConnectProvider = (itemWallet) => {
  if (itemWallet && itemWallet.provider) {
    return <img src={`/img/icons/auth/${itemWallet.provider}.svg`} className={'provider-sm-icon'} alt={itemWallet.provider} title={itemWallet.provider} />
  }
}

export const passPhrase = () => {
  return "Public Global Stellar Network ; September 2015"
}

export const getLockStatus = (account) => {

  let med = account.thresholds.med_threshold;
  let signers_weight = 0;
  let status

  account.signers.forEach(function (item) {
    signers_weight = (item.weight + signers_weight);
  });

  if (signers_weight === 0 ) { // false emission
    status = true
  }
  else if (signers_weight < med ) { // false emission
    status = true
  }
  else { // true emission
    status = false
  }

  return status
}

export const formatCount = (count, withAbbr = false, decimals = 2) => {
  const COUNT_ABBRS = [ '', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y' ];
  const i     = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result  = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
}


export const change7d = (val) => {

  // console.log('val: ', val)

  val.records = val.records ? val.records : val

  var today = val.records.length > 0 ? Number((1 / val.records[0].close).toFixed(7)) : 0
  var week = val.records.length >= 7 ? Number((1 / val.records[6].close).toFixed(7)) : 0

  val.chart7d = val.records.filter((item, index) => index < 7)

  if (today > week) {
    more();
  }
  else if (today === week) {
    val.change_7d_color = 'secondary';
    val.change7d = '0.00';
  }
  else {
    less();
  }

  function more() {
    // val.sign = '+';
    val.change7d = '+'+Number(today / week * 100 - 100).toFixed(2);
    val.change_7d_color = 'success';
  }

  function less() {
    val.change7d = Number(100 - week * 100 / today).toFixed(2);
    val.change_7d_color = 'danger';
  }

  // 7d
  // val.chart7d = [];
  // val.records.forEach((item, index) => {
  //   if (index+1 <= 7) {
  //     var price = Number((item.close_r.d / item.close_r.n).toFixed(7));
  //     if (val.records[0].close > item.close) {
  //       price = (price * 1.5).toFixed(7)
  //     }
  //     else if (val.records[0].close < item.close) {
  //       price = (price / 1.5).toFixed(7)
  //     }
  //     val.chart7d.unshift({ name: index, price: price });
  //   }
  // });

  return val;
}


export const change24 = (val) => {
  // console.log('val: ', val)

  var today = Number((1 / val.records[0].close).toFixed(6));
  var yestoday = Number((1 / val.records[val.records.length === 1 ? 0 : 1].close).toFixed(7));

  if (today > yestoday) {
    more();
  }
  else if (today === yestoday) {
    val.change_24h_color = 'secondary';
    val.change24 = '0.00';
  }
  else {
    less();
  }

  function more() {
    // val.sign = '+';
    val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
    val.change_24h_color = 'success';
  }

  function less() {
    val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
    val.change_24h_color = 'danger';
  }

  return val;
}


export const setLocalWallets = (walets) => {

  const stringify_wallets = JSON.stringify(walets);
  const base64_wallets = window.btoa(stringify_wallets);
  localStorage.setItem('wallets', base64_wallets);
}


export const parseNumber = (x) => {

  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10,e-1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10,e);
      x += (new Array(e+1)).join('0');
    }
  }
  return x;
}

export const getPoolIdFromAssets = (base_asset, counter_asset) => {

  const orderAssets = (A, B) => {
    return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
  }

  const assetA = base_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
  const assetB = counter_asset.asset_issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)

  const [ A, B ] = orderAssets(...[assetA, assetB]);
  const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
  const poolId = StellarSdk.getLiquidityPoolId("constant_product", poolShareAsset.getLiquidityPoolParameters()).toString("hex");

  return poolId
}

export const randomAmount = (min, max) => {
  return (Math.random() * (+max - +min) + +min).toFixed(7);
}


export const getRefLevel = (balance) => {

  let level = 0

  if (balance < 500) level = 5 // 1
  if (balance >= 500 && balance < 1000) level = 10 // 2
  if (balance >= 1000 && balance < 5000) level = 15 // 3
  if (balance >= 5000) level = 20 // 4

  return level;
}


export const getRefPositionLevel = (level, position) => {

  let new_level = 0

  if (position === 1) new_level = (level + 10)
  if (position === 2) new_level = (level + 7.5)
  if (position === 3) new_level = (level + 5)
  if (position === 4) new_level = (level + 2.5)

  return (new_level ? new_level : level);
}


export const getAPY = (type) => {

  let apy

  if (type === 'pool') apy = 14
  if (type === 'holder') apy = 10
  if (type === 'referral') apy = 5
  if (type === 'partner') apy = 20

  return apy;
}


export const domainValid = (domain) => {
  const reg = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  return  reg.test(domain)
}


export const getSCOPAsset = () => {
  return {
    code: 'SCOP',
    issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
    image: 'https://scopuly.com/img/logo/icon.png',
  }
}


export const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);


export const orderAssets = (A, B) => {
  return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
}


export const getFloatValue = (value) => {

  let new_val = '', count = 0;
  for (const key of Object.keys(value)) {
    new_val = (new_val + (Object.keys(value).length-1 > count ? value[key] : ''))
    count++
  }

  return new_val
}


export const getSelectAddress = (value) => {

  let address = '';
  for (const key of Object.keys(value)) {
    if (key < 56) {
      address = (address + value[key])
    }
    else {
      return address
    }
  }
}

export const getDurationTime = (start_time) => {

  const dtc = new Date();
  const dtmb = new Date(start_time);
  const qminutes = Math.floor((dtc-dtmb)/1000/60);
  const hours = Math.floor(qminutes/60);
  const minutes = new Date(dtc - dtmb).getMinutes()
  const seconds = new Date(dtc - dtmb).getSeconds()
  const duration = `${hours > 9 ? hours : `0${hours}` }:${minutes > 9 ? minutes : `0${minutes}` }:${seconds > 9 ? seconds : `0${seconds}` }`
  return duration
}


export const getStellarFee = () => {

  const fee = localStorage.getItem('stellar_fee')
  if (fee) return fee
  return 100
}


export const stellarStroop = () => {
  return 0.0000001
}


export const animateElems = () => {

  function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) {
        change.target.classList.add('element-show');
      }
    });
  }

  let options = {
    threshold: [0.2] };
  let observer = new IntersectionObserver(onEntry, options);
  let elements = document.querySelectorAll('.element-animation');

  for (let elm of elements) {
    observer.observe(elm);
  }
}


export const checkWatchlist = (pool_id) => {

  let local_favorites = localStorage.getItem('favorites');

  if (local_favorites) {
    local_favorites = JSON.parse(local_favorites);

    return local_favorites.pools && local_favorites.pools.some((item) => item === pool_id)
  }

  return false
}

export const renderPoolItem = (item, index, rewards_aqua, page) => {

  // console.log('renderPoolItem item: ', item)

  const stellar_logo = '/img/logo/stellar-logo.png'
  const default_logo = '/img/digitalcoin.png'
  const asset_a = item.issuer_a !== 'native' ? `${item.code_a}:${item.issuer_a}` : 'native'
  const asset_b = item.issuer_b !== 'native' ? `${item.code_b}:${item.issuer_b}` : 'native'
  const image_a = item.issuer_a !== 'native' ? !item.asset_a ? default_logo : item.asset_a.image : stellar_logo
  const image_b = item.issuer_b !== 'native' ? !item.asset_b ? default_logo : item.asset_b.image : stellar_logo
  const domain_b = !item.asset_b || !item.asset_b.home_domain ? 'domain not found' : item.asset_b.home_domain

  const is_watchlist = checkWatchlist(item.pool_id)

  if (item.asset_a && item.asset_b) {

    const rewards = rewards_aqua.filter(pair =>  pair.asset1 === (pair.asset1 === 'native' ? item.issuer_a : `${item.asset_a.asset_code}:${item.asset_a.asset_issuer}`) && pair.asset2 === `${item.asset_b.asset_code}:${item.asset_b.asset_issuer}`)

    const percent_amm = rewards.length ? (rewards[0].sdex_reward_value / item.total_vol_usd * 365 ).toFixed(2) : 0

    // console.log('rewards: ', rewards)
    // console.log('percent_amm: ', percent_amm)

    return (
      <Card className={'liquidity-item'} key={index}>
        <CardBody>

          {
            page === 'pools' ?
              <div>
                <small className={'pool-counter'}>{index+1}</small>

                <div className={'star-icon'}>
                  <a href="#" onClick={(e) => this.toggleWatchlist(e, item)}><i className={`fa fa-star-o text-${is_watchlist ? 'warning' : 'secondary' }`}></i></a>
                </div>
              </div>
              : null
          }

          <Row>
            <Col xs={12} md={3} className={'item'}>

              <div>

                <Link to={`/pool/${item.pool_id}`}>
                  <div className={`icon-asset-pair ${asset_a === 'native' && 'native-item'}`}>
                    <img src={image_a} alt=""/>
                  </div>
                  <div className={'icon-asset-pair ml-44'}>
                    <img src={image_b} alt=""/>
                  </div>

                  <div className={'ml-100'}>
                  <span>
                    <span className={'fs-16 text-primary'}><b>{item.code_a}</b> / <b>{item.code_b}</b></span>
                    <div className={'text-secondary'} style={{marginTop: -4}}>
                      <small className={'overflow'}>
                        <span>{item.asset_a.home_domain}</span> / {' '}
                        <span>{domain_b}</span>
                      </small>
                    </div>
                  </span>
                  </div>
                </Link>
              </div>
            </Col>
            <Col xs={4} md={1} className={'item text-center hide-xs'}>
              <p className={'text-secondary'}>
                {/*<small className={'overflow'}>{item.asset_a.home_domain}</small>*/}
                {/*<small className={'overflow'}>{domain_b}</small>*/}

                <small className={'overflow'}>{item.asset_a && item.asset_a.name !== '' && item.asset_a.name !== 'undefined' && item.asset_a.name !== '...' ? item.asset_a.name : item.code_a}</small>
                <small className={'overflow'}>{item.asset_b && item.asset_b.name !== '' && item.asset_b.name !== 'undefined' && item.asset_b.name !== '...' ? item.asset_b.name : item.code_b}</small>
              </p>
            </Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Liquidity</small><h4><b className={'text-info fs-16'}>${item.total_vol_usd > 1 ?formatCount(item.total_vol_usd, true, 2) : '0.00'}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Vol 24H</small><h4><b className={'text-info fs-16'}>${item.vol_24h > 1 ? formatCount(item.vol_24h, true, 2) : '0.00'}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Fee 24H</small><h4><b className={'text-info fs-16'}>${numFormat(item.fee_24h, 2)}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}>
              <small className={'text-secondary'}>APY</small>
              <h4><b className={'text-success fs-16'}>{item.apr}%</b></h4>
              {
                rewards.length && page === 'pool' ?
                  <div className={'text-secondary rewards text-left'}><small className={'label-rewards color-aqua'}><b>+{numFormat(percent_amm)}% AQUA</b></small></div>
                  : null
              }
              {
                item.pool_id === getRewardPools()[0] ||
                item.pool_id === getRewardPools()[1] ||
                item.pool_id === getRewardPools()[2] ||
                item.pool_id === getRewardPools()[3] ||
                item.pool_id === getRewardPools()[4] ||
                item.pool_id === getRewardPools()[5] ||
                item.pool_id === getRewardPools()[6] ||
                item.pool_id === getRewardPools()[7] ?
                  <div className={'text-secondary rewards text-left'}><small className={'label-rewards color-scop'}><b>+{this.apy_scop}% SCOP</b></small></div>
                  : null
              }
            </Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Shares</small><h4><b className={'text-info fs-16'}>{item.shares > 1 ? formatCount(item.shares, true, 2) : numFormat(item.shares, 2)}</b></h4></Col>
            <Col xs={4} md={1} className={'item text-center'}><small className={'text-secondary'}>Trustlines</small><h4><b className={'text-info fs-16'}>{formatCount(item.trustlines, true, 2)}</b></h4></Col>
            {/*<Col xs={4} md={1} className={'item'}><p className={'text-secondary'}>Pool</p><span><b>xxxx...xxxx</b></span></Col>*/}
            <Col xs={9} md={2} className={'item'}>
              <div className={'text-right liquid-btn'}><Link to={`/pool/${item.pool_id}`} className={'btn btn-outline-success m-0'}>Add Liquidity</Link></div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

}


export const balancesFormat = (balances) => {

  const query = {
    pools: [],
    codes: [],
    issuers: [],
  }

  balances.map( (item, index) => {
    // console.log('item: ', item)

    if (item.asset_type === 'liquidity_pool_shares') {
      query.pools.push(item.liquidity_pool_id)
    }
    else {

      if (item.asset_type !== 'native') {
        query.codes.push(item.asset_code)
        query.issuers.push(item.asset_issuer)
      }
    }
  })

  return query
}


export const fetchUrl = async (url) => {
  const response = await fetch(url);
  return await response.json();
}


export const getRewardPools = () => {

  return [
    'feb83f3fe1ab32874787c96211b5c087c1f1ffdbeee6677c6d17b96f36c9b470', // 0 SCOP
    '492f21d8c29d8523cc8a4adc33302484389e15f9f6ef53db59f76d429338cf97', // 1 AQUA
    '1b9ad45819d885acbc0563f20346fea19804238a334d7645ac069357d78cbb87', // 2 USDC
    '4e999c117fd77d2d194e643607a9501be5aef3353cd6258707d06bf0a467f3ed', // 3 yXLM
    '933bbda5f08b0fa2202eae149365e3cc39bfede671f529a253cc180cbaa02bf1', // 4 LSP
    '6372921738865f448de54a39093aad6796728c9b643dfe3ce85fe419a2de77b8', // 5 XXA
    'a6478345327b4c2123217933b7a4a286bc1d78bb9cb6d8d4fffbc75115ce6112', // 6 SKY
    '19928dd01ca16ed146163782b73eb3da530f36cdd650a2c4be65be5245b3d7fb', // 7 MOBI
    '9a95b95c9744d237be7a5b0a0f377810d9dbdf434e7b586b005a2e2bd99b7bb4', // 8 XRP / fchain
    '9975fbcd5d0e9a7569fd52a450d5c0555570015c9fe3812ff312d8aca79550b8', // 9 yUSDC
    '09dfd7cd30036fc8bbb36a37a4e8e6118298496e63b1e38f1603d04d2383cdba', // 10 yBTC
    '4697dec247435b9e98b815b55539ab9b4651ada66ec735958878c8da46930306', // 11 yETH

    '7a3bb88a3e5b0dc77d6b5f8ee31f6b77bc4f5c239a373bec8fe26c889ba01003', // 12 FIDR
    '483421c73aaafb9d8fce344c564bde2712b0eab9a797b7bea20d7c465fc56f6a', // 13 FRED

    // '346f796d4bb5e64637a7e95199f5b6384024ce7be23de4d4681fffd5c7df92cd', // 7 AFR
  ]
}

