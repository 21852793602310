import React, { PureComponent, useState } from 'react';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Server } from '../../../../../modules/stellar/index';
import {
  numFormat,
  shortAddress, getAsset,
} from '../../../../../modules/index';
import {css} from "@emotion/core";
import StellarSdk from "stellar-sdk";



class BalanceItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      amount_usd: 0,
      asset: false,
      price_usd: 0,
    };
  }

  xlm_asset = {
    asset_code: 'XLM',
    asset_issuer: 'native',
    name: 'Stellar',
    image: '/img/logo/stellar-logo.png',
    home_domain: 'stellar.org',
  }


  componentDidMount() {

    this.getAssetPrice(this.props.item)
  }



  getAssetPrice = async (item) => {

    const { price, pools, assets } = this.props

    if (item.asset_type === 'native' ) {
      const amount_usd = (item.balance * price)
      this.setState({ amount_usd, price_usd: price })

      setTimeout(() => {
        this.props.totalAssetsUsd(amount_usd)
      }, 100)
    }

    else if (item.pool) {

      const asset = assets.filter(asset => asset.asset_code === item.pool.code_a && asset.asset_issuer === item.pool.issuer_a)[0]

      if (asset) {

        const reserves_a = item.pool.amount_a
        const total = item.pool.shares
        const amountA = Number((reserves_a / total * item.balance).toFixed(7))

        const amount_usd = item.pool.issuer_a === 'native' ? (amountA * price) : ((amountA * asset.price) * price)

        setTimeout(() => {
          this.props.totalAssetsUsd(amount_usd * 2)
        }, 100)
      }
    }

    else {

      if (item.asset) {
        const amount_usd = (item.balance * item.asset.price) * price
        const price_usd = (item.asset.price * price)
        this.setState({ amount_usd, price_usd })

        setTimeout(() => {
          this.props.totalAssetsUsd(amount_usd)
        }, 100)
      }
      else if (item.asset_type !== "liquidity_pool_shares") {

        const base_asset = new StellarSdk.Asset.native()
        const counter_asset = new StellarSdk.Asset(item.asset_code, item.asset_issuer)

        const lastTrade = await Server.trades().forAssetPair(base_asset, counter_asset).order('desc').limit(1).call()

        let asset_price = lastTrade.records[0].price

        if (asset_price) {

          asset_price = (asset_price.d / asset_price.n)
          const price_usd = (asset_price * price)
          const amount_asset_xlm = (item.balance * asset_price)
          const amount_usd = (amount_asset_xlm * price)

          this.setState({ amount_usd, price_usd })
          this.props.totalAssetsUsd(amount_usd)
        }
      }
    }
  }




  render() {

    const { item, assets } = this.props
    const { amount_usd, price_usd } = this.state


    const image = '/img/digitalcoin.png'
    let url = `/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`;
    let asset

    if (item.asset_type === 'native') {
      item.asset_code = 'XLM';
      url = `/native`;
      asset = this.xlm_asset
    }


    if (item.asset_type !== 'liquidity_pool_shares' && item.asset_type !== 'native') {
      asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

      if (!asset) {
        asset = {
          asset_code: item.asset_code,
          asset_issuer: item.asset_issuer,
          image: image,
        }
      }
    }


    return (
      <span>

        {
          item.asset_type === 'native' ?
            <span className="chips_item">
              <span className="asset-amount">
                <img src={this.xlm_asset.image} className={'asset-icon-sm'} alt={'XLM'} style={{borderRadius: '50%', width: 20}}  /> {' '}
                  <Link to={'/native'} className={'text-warning'}><b>XLM</b></Link> {' '}

                  <b className="text-info">{numFormat(item.balance, 7)}</b> <br/>
                <small className={'space-between text-secondary'}>
                  <span className={'mr-5'}><b>${ numFormat(amount_usd, 2) }</b></span>
                  <span>${ numFormat(price_usd, 7) }</span>
                </small>
              </span>
            </span>
          :
            item.asset_type !== 'liquidity_pool_shares' ?
              <span className="chips_item">
                <span className="asset-amount">{' '}

                  {
                    asset ?
                      <span>{getAsset(`${asset.asset_code}:${asset.asset_issuer}`, asset.image)} &nbsp;</span>
                      : null
                  }

                  <b className="text-info">{numFormat(item.balance, 7)}</b> <br/>
                <small className={'space-between text-secondary'}>
                  <span className={'mr-10'}><b>${ numFormat(amount_usd, 2) }</b></span>
                  <span>${ numFormat(price_usd, 7) }</span>
                </small>
              </span>
              </span>
          : null
        }
      </span>

    );
  }
}



export default (translate('common')(BalanceItem));
